<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Events.EventTags.Edit.Main_Title')"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveEvent"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div>
          <tag-input :tags="tags" @tagAdded="addTag" @tagDeleted="deleteTag" />
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

import TagInput from '@/components/Tags/TagInput'

export default {
  components: {
    'tag-input': TagInput,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      tags: [],
      mTags: [],
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),
  },

  created() {
    this.tags =
      this.eventData.Tags.trim().length > 0
        ? this.eventData.Tags.trim().split(',')
        : []

    this.mTags = JSON.parse(JSON.stringify(this.tags))
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    addTag(tag) {
      this.mTags.push(tag)
    },

    deleteTag(tag) {
      let tagIndex = this.mTags.findIndex((t) => t === tag)
      if (tagIndex > -1) {
        this.mTags.splice(tagIndex, 1)
      }
    },

    saveEvent() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let eventObj = JSON.parse(JSON.stringify(self.eventData))
        eventObj.Tags = self.mTags.join(',')

        eventProvider.methods
          .updateEvent(eventObj)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setEventData(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((e) => {
            ////console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.tags-main {
  display: flex;
  align-items: flex-start;
  height: 32px;
  .tags-sub {
    height: 100%;
    margin-right: 10px;
    .tag {
      &.is-link {
        background-color: $grey !important;
      }
    }
  }
  .select {
    height: 100%;
    .sub {
      height: 100%;
    }
  }
  .button {
    display: flex;
    align-items: center;
    &.green {
      background-color: $green;
    }
  }
}
</style>
